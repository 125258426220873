import React, { useEffect, useRef, useState } from 'react'
import { Link, FlexboxRow as Row } from 'src/components/Base'
import MenuDropdown from 'src/components/MenuDropdown'
import PropTypes from 'prop-types'
import LanguageSwitcher from 'src/components/LanguageSwitcher'

import {
  cActive,
  cDropdown,
  cLink,
  cList,
  cListElement,
  cLangRow,
} from './styles.module.scss'

// Outside Click Event Handler based on https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideAlerter(ref, setOpenDropDown) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropDown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const MainMenuDesktop = ({ data, className, currentLanguage, translation }) => {
  const [openDropDown, setOpenDropDown] = useState(null)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setOpenDropDown)


  return (
    <nav ref={wrapperRef} className={className}>
      <Row className={cLangRow}>
        <LanguageSwitcher
          currentLanguage={currentLanguage}
          translation={translation}
        />
      </Row>
      <ul className={cList}>
        {data.map((item, index) => {

          const hasSublinks = item?.sublinks && item?.sublinks?.length > 0

          return (
            <li key={item} className={cListElement}>
              {hasSublinks ? (
                <MenuDropdown
                  className={cDropdown}
                  dropdownItems={
                    item.sublinks && item.sublinks.length > 0
                      ? item.sublinks : []
                  }
                  isOpen={openDropDown === index}
                  index={index}
                  setOpenDropDown={setOpenDropDown}
                >
                  {item.link.title}
                </MenuDropdown>
              ) : (
                <Link
                  to={item?.link?.url}
                  className={cLink}
                  activeClassName={cActive}
                  onClick={() => {
                    setOpenDropDown(null)
                  }}>
                  {item?.link?.title}
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

MainMenuDesktop.propTypes = {
  data: PropTypes.array.isRequired,
}

export default MainMenuDesktop
