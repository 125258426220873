import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { useContext, useEffect, useState } from 'react'
import Logo from 'src/assets/images/brand.inline.svg'
import { Container, Link, FlexboxRow as Row } from 'src/components/Base'
import MainMenuDesktop from 'src/components/Menues/MainMenuDesktop'
import MainMenuMobile from 'src/components/Menues/MainMenuMobile'
import LanguageContext from 'src/context/LanguageContext'
import LanguageSwitcher from 'src/components/LanguageSwitcher'

import {
  cContainer,
  cContainerIsFixed,
  cHeader,
  cHeaderIsFixed,
  cHeaderLogo,
  cHeaderRow,
  cLogo,
  cMenuBar,
  cMenuClose,
  cMenuToggle,
  cNavbar,
  cMenuInteractions,
} from './styles.module.scss'

const Navbar = ({ currentLanguage, translation }) => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            plakativMenu {
              fieldGroupName
              mainMenuDe {
                fieldGroupName
                menuLink {
                  fieldGroupName
                  link {
                    title
                    url
                  }
                  sublinks {
                    fieldGroupName
                    sublink {
                      title
                      url
                    }
                  }
                }
              }
              mainMenuEn {
                fieldGroupName
                menuLink {
                  fieldGroupName
                  link {
                    title
                    url
                  }
                  sublinks {
                    fieldGroupName
                    sublink {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const breakpoints = useBreakpoint()
  const scrollTrigger = 50
  const [isFixed, stIsFixed] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuLinks, setMenuLinks] = useState([])
  const languageContext = useContext(LanguageContext)

  const menuCloseClasses = classNames({
    [cMenuBar]: true,
    [cMenuClose]: true,
  })

  const headerClasses = classNames({
    [cHeader]: true,
    [cHeaderIsFixed]: isFixed,
  })

  const containerClasses = classNames({
    [cContainer]: true,
    [cContainerIsFixed]: isFixed,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset

    if (scrollPosition >= scrollTrigger) {
      stIsFixed(true)
    } else {
      stIsFixed(false)
    }
  }

  // update menu links on language change
  const menuLinksEn =
    data?.allWp?.nodes[0]?.themeGeneralSettings?.plakativMenu?.mainMenuEn
      ?.menuLink
  const menuLinksDe =
    data?.allWp?.nodes[0]?.themeGeneralSettings?.plakativMenu?.mainMenuDe
      ?.menuLink

  // parent menu links and sublinks could be empty, because they are not fully deleted in the backend
  // so we need to filter them out
  const filteredMenuLinksEn = menuLinksEn?.filter(
    (link) => link?.link?.title && link?.link?.url
  )
  const filteredMenuLinksDe = menuLinksDe?.filter(
    (link) => link?.link?.title && link?.link?.url
  )

  useEffect(() => {
    switch (languageContext.currentLanguage) {
      case 'en':
        setMenuLinks(filteredMenuLinksEn)
        break
      default:
        setMenuLinks(filteredMenuLinksDe)
        break
    }
  }, [languageContext.currentLanguage])

  return (
    <header className={headerClasses}>
      <Container className={containerClasses} isBoxed={false}>
        <Row className={cHeaderRow}>
          <Link
            to={`/${languageContext.currentLanguage}`}
            className={cHeaderLogo}
          >
            <Logo className={cLogo} />
          </Link>
          {!breakpoints.nav && (
            <MainMenuDesktop
              data={menuLinks}
              className={cNavbar}
              translation={translation}
              currentLanguage={currentLanguage}
            />
          )}
          {breakpoints.nav && (
            <div className={cMenuInteractions}>
              <LanguageSwitcher
                currentLanguage={currentLanguage}
                translation={translation}
              />
              <button
                title="Menu Button"
                className={cMenuToggle}
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
              >
                {!menuOpen ? (
                  <>
                    <span className={cMenuBar}></span>
                    <span className={cMenuBar}></span>
                    <span className={cMenuBar}></span>
                  </>
                ) : (
                  <>
                    <span className={menuCloseClasses}></span>
                    <span className={menuCloseClasses}></span>
                  </>
                )}
              </button>
            </div>
          )}
        </Row>
      </Container>
      {menuOpen && breakpoints.lg && (
        <MainMenuMobile
          data={menuLinks}
          setMenuOpen={setMenuOpen}
          translation={translation}
          currentLanguage={currentLanguage}
        />
      )}
    </header>
  )
}

export default Navbar
