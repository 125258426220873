import React, { useState, useEffect, useContext } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { Button, Text, Link } from 'src/components/Base'
import { v4 as uuid } from 'uuid'
import * as styles from './styles.module.scss'
import classNames from 'classnames'
import LanguageContext from 'src/context/LanguageContext'

const CookieSettings = ({
  acceptAllCookies,
  rejectAllCookies,
  cookies,
  cookieGuidelinesLink,
  saveAndExitSettings,
  heading,
  text,
  acceptAllButtonText,
  rejectAllButtonText,
  saveButtonText,
  defaultCookiesText,
  defaultCookiesInfoText,
}) => {
  const defaults = cookies.map((cookie) => cookie.event)

  const [activeEvents, setActiveEvents] = useState(defaults)

  const languageContext = useContext(LanguageContext)

  useEffect(() => {
    setActiveEvents([])
  }, [])

  const isCheckboxChecked = (eventName) => activeEvents.includes(eventName)

  const essentialClasses = classNames({
    [styles.cookieSwitch]: true,
    [styles.cookieSwitchEssential]: true,
  })

  const addActiveEvent = (event) => {
    if (!activeEvents.includes(event)) {
      setActiveEvents([...activeEvents, event])
    }
  }

  const removeActiveEvent = (event) => {
    setActiveEvents(activeEvents.filter((item) => item !== event))
  }

  const handleChange = (event) => {
    if (isCheckboxChecked(event)) {
      removeActiveEvent(event)
    } else {
      addActiveEvent(event)
    }
  }

  /*
  // NOTE: Remove - Debugging only
  useEffect(() => {
    console.log(activeEvents)
  }, [activeEvents])
*/

  cookies[0].event = 'userExperience'
  cookies[1].event = 'targetingMarketing'

  const cookiesMapped = cookies.map((cookie) => {
    const cookieID = cookie.cookieName.split(' ').join('')
    return (
      <AccordionItem key={uuid()} eventKey={uuid()} className={styles.cookie}>
        <AccordionItemHeading className={styles.row}>
          <AccordionItemButton className={styles.cookiesHeader}>
            {languageContext.currentLanguage === 'en'
              ? cookie.cookieNameEn
              : cookie.cookieName}
            <div className={styles.checkboxWrapper}>
              <input
                className={styles.checkbox}
                type="checkbox"
                id={cookieID}
                checked={isCheckboxChecked(cookie.event)}
                onClick={() => handleChange(cookie.event)}
              />
              <label htmlFor={cookieID} className={styles.labelSwitch}>
                <span className={styles.cookieSwitch}></span>
              </label>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.cookieText}>
          {languageContext.currentLanguage === 'en'
            ? cookie.infoTextEn
            : cookie.infoText}
        </AccordionItemPanel>
      </AccordionItem>
    )
  })

  return (
    <div className={styles.modal}>
      <div className={styles.cookiesettings}>
        <div className={styles.header}>
          <p className={styles.heading}>{heading}</p>
          <Text className={styles.cookieSettingsText}>
            {ReactHtmlParser(text)}
            <Link className={styles.cookiesLink} to={cookieGuidelinesLink.url}>
              {cookieGuidelinesLink.title}
            </Link>
          </Text>
          <div className={styles.buttons}>
            <Button
              className={styles.buttonReject}
              color="secondary"
              onClick={rejectAllCookies}
            >
              {rejectAllButtonText}
            </Button>
            <Button
              className={styles.buttonAccept}
              color="primary"
              onClick={acceptAllCookies}
            >
              {acceptAllButtonText}
            </Button>
          </div>
        </div>

        <div className={styles.body}>
          <Accordion allowZeroExpanded flush>
            <AccordionItem eventKey={uuid()} className={styles.cookie}>
              <AccordionItemHeading className={styles.row}>
                <AccordionItemButton className={styles.cookiesHeader}>
                  {defaultCookiesText}
                  <div className={styles.checkboxWrapper}>
                    <input
                      type="checkbox"
                      checked
                      disabled
                      className={styles.checkbox}
                    />
                    <label className={styles.labelSwitch}>
                      <span className={essentialClasses}></span>
                    </label>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles.cookieText}>
                {defaultCookiesInfoText}
              </AccordionItemPanel>
            </AccordionItem>
            {cookiesMapped}
          </Accordion>
        </div>

        <div className={styles.footer}>
          <Button
            className={styles.save}
            color="secondary"
            onClick={() => saveAndExitSettings(activeEvents)}
          >
            {saveButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

CookieSettings.propTypes = {
  acceptAllCookies: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  saveAndExitSettings: PropTypes.func.isRequired,
  cookies: PropTypes.arrayOf(
    PropTypes.shape({
      cookieName: PropTypes.string.isRequired,
      desc: PropTypes.string,
      event: PropTypes.string.isRequired,
    })
  ),
  heading: PropTypes.string,
  text: PropTypes.string,
  acceptAllButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  defaultCookiesText: PropTypes.string,
}

CookieSettings.defaultProps = {
  cookies: [],
  heading: 'Cookie Settings',
  text: '',
  acceptAllButtonText: 'Accept all cookies',
  saveButtonText: 'Save and exit',
  defaultCookiesText: 'Functional cookies',
}

export default CookieSettings
