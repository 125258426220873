import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Link } from 'src/components/Base'
import { v4 as uuid } from 'uuid'

import {
  cCurrentItem,
  cCurrentItemOpen,
  cDropdownButton,
  cDropdownIcon,
  cDropdownIconOpen,
  cDropdownLink,
  cDropdownList,
  cDropdownListContainer,
  cDropdownListContainerColliding,
  cDropdownListContainerOpen,
  cDropdownListElement,
  cIsActive,
} from './styles.module.scss'

const MenuDropdown = ({
  children,
  className,
  dropdownItems,
  onChange,
  isOpen,
  setOpenDropDown,
  index,
  ...props
}) => {
  const [dropdownColliding, setDropdownColliding] = useState(false)
  const [isDropdownActive, setIsDropdownActive] = useState(false)

  const dropDownRef = useRef()

  const buttonClasses = classNames({
    [cDropdownButton]: true,
    [className]: !!className,
    [cIsActive]: isDropdownActive,
  })

  const dropdownIconClasses = classNames({
    [cDropdownIcon]: true,
    [cDropdownIconOpen]: isOpen,
  })

  const dropdownListClasses = classNames({
    [cDropdownListContainer]: true,
    [cDropdownListContainerOpen]: isOpen,
    [cDropdownListContainerColliding]: dropdownColliding,
  })

  const dropdownItemClasses = classNames({
    [cCurrentItem]: true,
    [cCurrentItemOpen]: isOpen,
  })

  const path = window.location.pathname

  useEffect(() => {
    const changeDropDownPosition = () => {
      // Set Element to Block, otherwise you wont get width and position
      dropDownRef.current.style.display = 'block'
      // save position of right end of the element
      const viewportOffset = dropDownRef.current.getBoundingClientRect().right
      dropDownRef.current.style.removeProperty('display')
      // If right position is larger than the window-width, it would overflow
      if (viewportOffset > window.innerWidth) {
        setDropdownColliding(true)
      } else {
        setDropdownColliding(false)
      }
    }

    // Use function at the start and add event listener to window change
    changeDropDownPosition()
    window.addEventListener('resize', changeDropDownPosition)

    // If component would unmount, we remove the listener
    return () => window.removeEventListener('resize', changeDropDownPosition)
  }, [])

  useEffect(() => {
    const setParentActive = () => {
      const links = dropDownRef.current.querySelectorAll('a')
      const activeLink = Array.from(links).find(
        (link) => link.pathname === path
      )
      setIsDropdownActive(activeLink)
    }

    setParentActive()
  }, [path])

  return (
    <Button
      className={buttonClasses}
      onClick={() => (isOpen ? setOpenDropDown(null) : setOpenDropDown(index))}
      {...props}
    >
      <span className={dropdownItemClasses}>{children}</span>
      <svg className={dropdownIconClasses} width="8" height="6.4">
        <path
          id="navigation-arrow"
          d="M4,0,8,6.4H0Z"
          transform="translate(8 6.4) rotate(180)"
          fill="#7f7f7f"
        />
      </svg>
      <div className={dropdownListClasses} ref={dropDownRef}>
        {dropdownItems && (
          <ul className={cDropdownList}>
            {dropdownItems.map((dropdownItem) =>
              dropdownItem?.sublink?.url ? (
                <li key={uuid()} className={cDropdownListElement}>
                  <Link
                    to={dropdownItem.sublink.url}
                    className={cDropdownLink}
                    barColor={dropdownItem.color}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: dropdownItem.sublink.title,
                      }}
                    />
                  </Link>
                </li>
              ) : null
            )}
          </ul>
        )}
      </div>
    </Button>
  )
}

MenuDropdown.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
}

export default MenuDropdown
