import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from 'src/components/Footer'
import Topbar from 'src/components/Topbar'
import Navbar from 'src/components/Navbar'
import Seo from 'src/components/Seo'

import CookieConsent from '../CookieConsent'

import './styles.scss'
import 'normalize.css'
import 'src/theme/theme.scss'

const Layout = ({
  children,
  translation,
  currentLanguage,
  isProject,
  isPost,
}) => {
  // Query for getting the site metadata
  const seoQuery = useStaticQuery(graphql`
    query seoQuery {
      allWpPage {
        edges {
          node {
            link
            seo {
              title
              metaDesc
            }
          }
        }
      }
      allWpProject {
        edges {
          node {
            link
            seo {
              title
              metaDesc
            }
          }
        }
      }
      allWpPost {
        edges {
          node {
            link
            seo {
              title
              metaDesc
            }
          }
        }
      }
    }
  `)

  // Browsercheck & Data definition
  let seoData

  if (isProject) {
    seoData = seoQuery.allWpProject.edges
  } else if (isPost) {
    seoData = seoQuery.allWpPost.edges
  } else {
    seoData = seoQuery.allWpPage.edges
  }

  const isBrowser = typeof window !== 'undefined'
  let pageSeo
  if (isBrowser) {
    // Query for the correct metadata node
    function getSeoData(node) {
      // drop trailing slashes on the way (error prevention and such)
      return (
        (node.node.link.substr(-1) === '/'
          ? node.node.link.slice(0, -1)
          : node.node.link) ===
        (window.location.pathname.substr(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname)
      )
    }

    pageSeo = seoData?.find(getSeoData)?.node
  }

  return (
    <>
      <Seo title={pageSeo?.seo.title} description={pageSeo?.seo.metaDesc} />
      <Topbar />
      <Navbar currentLanguage={currentLanguage} translation={translation} />
      <main>
        {children}
        <CookieConsent />
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
