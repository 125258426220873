import React, { useContext } from 'react'
import Layout from './src/components/Layout'
import LanguageContext, {
  LanguageProvider,
} from './src/context/LanguageContext'
import { getLanguageFromUrl } from './src/utils/language'

const WrapLayout = ({ element, props }) => {
  const languageContext = useContext(LanguageContext)
  languageContext.setCurrentLanguage(getLanguageFromUrl())

  let translation = props.pageContext.translation

  // we might find a better solution here
  if (translation?.startsWith('/de/de/')) {
    translation = translation.substring(3)
  }

  return (
    <Layout
      isProject={!!element.props.data?.wpProject}
      isPost={!!element.props.data?.wpPost}
      currentLanguage={props.pageContext.language}
      translation={translation}
    >
      {element}
    </Layout>
  )
}

const WrapPageElement = ({ element, props }) => (
  <LanguageProvider>
    <WrapLayout element={element} props={props} />
  </LanguageProvider>
)

export default WrapPageElement
