import React, { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LanguageContext from 'src/context/LanguageContext'
import { Section, Container, Link } from 'src/components/Base'
import {
  cBar,
  cBarContent,
  cBarTel,
  cBarLink,
  cBarIcon,
} from './styles.module.scss'

// Icons
import Phone from 'src/assets/images/phone.svg'
import CustomerSupport from 'src/assets/images/customer-support.svg'

const Topbar = () => {
  const { currentLanguage } = useContext(LanguageContext)
  const [locatedTopbarLink, setTopbarLink] = useState([])

  const data = useStaticQuery(graphql`
    query TopbarQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            plakativTopbar {
              fieldGroupName
              topbarphone
              topbarlink {
                title
                url
              }
              topbarlinken {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const { plakativTopbar } = data.allWp.nodes[0].themeGeneralSettings

  useEffect(() => {
    switch (currentLanguage) {
      case 'en':
        setTopbarLink(plakativTopbar?.topbarlinken)
        break
      default:
        setTopbarLink(plakativTopbar?.topbarlink)
        break
    }
  }, [currentLanguage])

  return (
    <Section className={cBar}>
      <Container className={cBarContent}>
        {plakativTopbar?.topbarphone && (
          <a href={`tel:${plakativTopbar?.topbarphone}`} className={cBarTel}>
            <img src={Phone} alt="tel" className={cBarIcon} />
            {plakativTopbar?.topbarphone}
          </a>
        )}
        {locatedTopbarLink?.url && (
          <Link to={locatedTopbarLink?.url} className={cBarLink}>
            <img
              src={CustomerSupport}
              alt="customer-support"
              className={cBarIcon}
            />
            {locatedTopbarLink?.title}
          </Link>
        )}
      </Container>
    </Section>
  )
}

export default Topbar
