import React from 'react'
import IconEn from 'src/assets/images/en.inline.svg'
import IconDe from 'src/assets/images/de.inline.svg'
import { cButton } from './styles.module.scss'
import { Link } from 'src/components/Base'

const LanguageSwitcher = ({ currentLanguage, translation, setMenuOpen }) => {
  const fallback = currentLanguage === 'de' ? '/en' : '/de'

  return (
    <Link
      to={translation || fallback}
      className={cButton}
      onClick={() => {
        !!setMenuOpen && setMenuOpen(false)
      }}
    >
      {currentLanguage === 'en' ? <IconDe /> : <IconEn />}
    </Link>
  )
}

export default LanguageSwitcher
